import React from 'react';
import PropTypes from 'prop-types';

import { Link, useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';

import articleStyles from './article.module.css';

const ArticleComponent = ({ article }) => {
  const articleImages = useStaticQuery(graphql`
    query ArticleComponentImageQuery {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "images/articles"}}) {
        edges {
          node {
            base
            name
            childImageSharp {
              fluid(quality: 70) {
                aspectRatio
                src
                srcSet
                sizes
                tracedSVG
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `);

  const images = articleImages.allFile.edges;
  const articleImage = images.find((image) => (image.node.name === article.image));

  return (
    <div className={articleStyles.articleContainer}>
      <div className={articleStyles.articleItemContainer}>
        <h1>{article.label}</h1>
        <div className={articleStyles.articleSubHeader}>
          <p className={articleStyles.articleSubHeaderLabel}>By Wow Interiors</p>
          {article.links.map((linkItem) => (
            <Link className={articleStyles.articleSubHeaderLink} key={`text_${linkItem.label}`} to={`/${linkItem.link}/`}>{linkItem.label}</Link>
          ))}
        </div>
        <div className={articleStyles.articleItemImage}>
          <Img
            fluid={articleImage.node.childImageSharp.fluid}
            alt={article.alt}
            fadeIn
          />
        </div>
        <div className={articleStyles.articleItemText}>
          {article.text.map((textSection, index) => {
            const key = `id_${index}`;
            return (
              <p key={key}>{textSection}</p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ArticleComponent;

ArticleComponent.propTypes = {
  article: PropTypes.object.isRequired,
};
