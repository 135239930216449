module.exports = [
  {
    label: 'Do fitted wardrobes add value to your house?',
    link: 'do-fitted-wardrobes-add-value-to-house',
    links: [
      {
        label: 'fitted wardrobes',
        link: 'portfolio/walk-in-wardrobe',
      },
      {
        label: 'bedrooms',
        link: 'portfolio/bedroom',
      },
      {
        label: 'dressing rooms',
        link: 'portfolio/walk-in-wardrobe',
      },
    ],
    text: [
      [
        'The intention of most of our customers when they commission us to develop fitted furniture for their home is first '
        + 'and foremost driven by a desire to improve the look of their home. '
        + 'People naturally like new things and most customers know in advance the style that they want to introduce to their home. '
        + 'Very rarely are our customers looking to introduce a new fitted bedroom suite or set of built in wardrobes with the intention of adding value to their home.',
      ],
      [
        'The good news is that this is likely to happen regardless if done in the right way!',
      ],
      [
        'With space at a premium in the UK, it stands to reason that if you can make the necessary improvements to your home to open up the room and maximum space, then this is bound to be more attractive for perspective buyers.',
      ],
      [
        'But why do fitted wardrobes add value to a property?',
      ],
      [
        'When viewing a property for the first time, the trick has always been to try and make it as easy as possible for people '
        + 'to imagine themselves living there. If your house comes across as cluttered and a tad claustrophobic, then this is only '
        + 'going to hamper your chances of sealing a sale. '
        + 'At Wow Interior Design our fitted wardrobes are built to meet the exact shape of the room, allowing any difficult corners '
        + 'or angled rooves to be taken into account. In utilising these difficult areas you can help to free up useful space elsewhere '
        + 'in the room.',
      ],
      [
        'It is this space that lends itself to the imagination of the potential house purchaser, who is likely to prefer to move into '
        + 'a home that has already been laid out with an eye on maximising space rather than one that does not have room to swing a cat! '
        + 'Although the introduction of a quality fitted wardrobe may not be cheap, you will definitely be able to recoup the benefit of '
        + 'this outlay when it comes to selling your property if you do the job right and hire a professional company to design and fit a '
        + 'high end product for you.',
      ],
      [
        'Contact us today to see how we can help you to add value to your home.',
      ],
    ],
    image: 'sell-a-house',
    alt: 'paper house cut-out held by hand on lush green grass emphasising control of your property',
  },
  {
    label: 'The finishing touches',
    link: 'the-finishing-touches',
    links: [
      {
        label: 'bespoke storage',
        link: 'portfolio/bespoke-storage-solutions',
      },
      {
        label: 'under stairs storage',
        link: 'portfolio/under-stairs-storage',
      },
    ],
    text: [
      [
        'At Wow Interior Design we believe that quality should never be compromised and as such we put a lot of time and effort '
    + 'into our fitted furniture designs with a view to ensuring every single part of the plan is considered. '
    + 'Our fitted bedrooms, kitchens and storage solutions are built to exact dimensions to ensure you get a highly polished finish – we do not take any '
    + 'short cuts that could negatively affect the overall feel of the design.',
      ],
      [
        'We are increasingly finding that it is often the small things that can make a big difference to the overall ambience that a '
    + 'new fitted design solution can bring to your home. ',
      ],
      [
        'A recent example of this can be seen with a job we worked on for a client who wanted us to create a set of integrated '
    + 'Ferrari-red fitted cupboards for use in their vintage sports car showroom. As well as adding sparkling '
    + 'lights to the walkway and the cupboard trim, we also needed to find some handles that would compliment the overall look and '
    + 'feel of the room.',
      ],
      [
        'Having spoken to our friends at \'More Handles\', we were able to select from their massive back catalogue ',
      ],
      [
        'The customer agreed and loved the overall effect of the handles on the look of the room – in fact it was one of the first things '
    + 'he commented on! We think it just goes to prove how it is often the understated aspects of a design that can often have a big effect '
    + 'on the final effect.',
      ],
      [
        'If you want us to come up with a stunning fitted furniture design for your home then please get in touch as we would be delighted '
    + 'to talk through the options with you for absolutely free.',
      ],
    ],
    image: 'finishing-touches',
    alt: 'inside of garage built for ferrari collection displaying bespoke fitted furniutre installed along the back wall',
  },
  {
    label: 'Have you seen the light?',
    link: 'have-you-seen-the-light',
    links: [],
    text: [
      [
        'As a general rule, when the sun is out we are a lot more likely to be walking around with a smile on our face. In fact it '
    + 'has actually been scientifically proven that the levels of sunlight that we are exposed to can have a direct influence on our '
    + 'mood, with more positive and energetic patterns of behaviour visible.',
      ],
      [
        'As such, it makes sense that one of the main factors that interior designers often look to improve when they work on a room is '
    + 'the levels of natural light that can make its way in.',
      ],
      [
        'The main benefit of this is that by allowing more natural light in, you are able to make the room feel bigger and more '
    + 'welcoming, removing any feeling of the room being dark, claustrophobic and inhospitable.',
      ],
      [
        'Of course some people may be seeking a darker or cosy atmosphere for their room, and it will differ from property to property '
    + 'depending on the style of the building and the homeowners tastes, however we tend to find that most people are keen to try to '
    + 'improve the levels of light that beam into a room.',
      ],
      [
        'What if this is not possible?',
      ],
      [
        'Sadly it is not always possible to add more windows to the walls of your room or introduce a skylight to allow more sunshine '
    + 'in from the ceiling. These changes are not always practical and certainly are not always cheap.',
      ],
      [
        'So what can you do to maximize the amount of light in your room? Here at Wow Interior Design we have listed some tips below:',
      ],
      [
        'Blinds - Let the light in!',
      ],
      [
        'It sounds silly, but a lot of the time there may be a really simple solution to letting light in as a lot of the time the lack '
    + 'of light is down solely to our own choices.',
      ],
      [
        'In removing bulking blinds or curtains or removing or changing obstructive netting to a more light-friendly alternative, you '
    + 'can have a massive impact on the general ambience of your room. '
    + 'If you have one window then it makes sense to make sure that the largest area of glass is on show and not hidden away. Small '
    + 'changes can make a massive difference.',
      ],
      [
        'Mirrors',
      ],
      [
        'As one of an interior designers best friends, mirrors can do a fantastic job of enhancing the existing natural light that is '
    + 'in your room.',
      ],
      [
        'Mirrors help to conjure up a feeling of extra space, so as well as being a practical introduction to any room, they also help '
    + 'to create a feeling that the room is bigger than it actually appears.',
      ],
      [
        'A tip is to place two mirrors of the same size opposite each other, as this can really make the room feel more open.',
      ],
      [
        'Colour',
      ],
      [
        'The colour of your walls and ceilings can be a strong influencing factor in how light and airy your room feels. ',
      ],
      [
        'We often recommend that your ceiling is painted a lighter colour than your walls, as this can help to open up the space at head '
    + 'height and removes any feel of claustrophobia. Light greys and bright white are good choices. ',
      ],
      [
        'If you have a dark room then you could look to introduce furniture that is lighter in colour and shade. You can always look to '
    + 'add a series of bolder colour statements in the selection of bold accessories (such as light shades and cushions) and '
    + 'appliances.',
      ],
    ],
    image: 'sunlight-entering-room',
    alt: 'empty room with large internal window allowing light to flood into room',
  },
  {
    label: 'Staying in neutral',
    link: 'staying-in-neutral',
    links: [
      {
        label: 'kitchens',
        link: 'portfolio/kitchen',
      },
      {
        label: 'lounge',
        link: 'portfolio/lounge-media-unit',
      },
    ],
    text: [
      [
        'For the past few years there have been key ‘on-trend’ colours to take into consideration when designing your home. We have '
    + 'had trends of lime greens and hot pinks, golds and silvers, but recently we are seeing an ever-growing demand for neutral '
    + 'colour schemes. You just cannot beat that clean, fresh feel that a room of creams, stones and beiges gives you as you walk in. ',
      ],
      [
        'We are very aware that bright, stand-out colour schemes are still the choice of many people across the country, but ‘Warmth’, '
    + '‘comfort’ and ‘homely’ are all adjectives that our clients regularly mention when meeting us for their design consultations.',
      ],
      [
        'Staying in neutral does not necessarily mean the traditional, wooden design. You can go glossy in your kitchen on some beautiful '
    + 'ivory coloured units. During the design process you can select some beautiful chrome handles for your dressing table drawers. '
    + 'Or, accessorise with some of the latest high-tech appliances such as electric fires, kettle '
    + 'and toaster sets from brands such as Neff and Smeg.',
      ],
      [
        'At Wow Interior Design we have a vast array of options available. Be it your kitchen, your bedroom, your study/office area, or your lounge, there is '
    + 'something for everyone.',
      ],
      [
        'If you would like to discuss the re-design of your kitchen, bedroom or office area, pop in for a free design consultation, '
      + 'or we can come to you on a free home visit to see what space you are looking to freshen up. ',
      ],
      [
        'We look forward to hearing from you!',
      ],
    ],
    image: 'neutral-kitchen',
    alt: 'large open plan modern kitchen with island unit finished in off white colour',
  },
  {
    label: 'Make the most of your space',
    link: 'make-the-most-of-your-space',
    links: [
      {
        label: 'angled wardrobes',
        link: 'portfolio/angled-wardrobe',
      },
      {
        label: 'bespoke storage',
        link: 'portfolio/bespoke-storage-solutions',
      },
      {
        label: 'under stairs storage',
        link: 'portfolio/under-stairs-storage',
      }],
    text: [
      [
        'At Wow Interior Design we are often brought in to introduce new fitted furniture into homes and we are surprised '
    + 'by the amount of space that is simply wasted by the previous furniture choice.',
      ],
      [
        'We always look to make the most of every area of the room. That means changing difficult spaces such as angled ceilings or '
        + 'hideaways into an area that provides a function. In utilising these previously ‘wasted’ areas of the room better, you can '
    + 'generate more space elsewhere in the room.',
      ],
      ['With years of experience in creating bespoke furniture that precisely fits your awkward spaces, we can also make a full carcase '
    + 'fit into that space as well. Leaving you with a fully fitted unit not just a frame & doors covering the space.',
      ],
      ['Whether it is a corner or U-shaped wardrobe, fully fitted office or awkward shaped kitchen. We will find a solution that will maximise your new design.'],
    ],
    image: 'awkward-spaces',
    alt: 'combination fitted bedroom furniture showing how a fiited wardrobe fits under sloping ceiling & returns into chest of drawers unit',
  },
  {
    label: 'Colour, technology and heritage',
    link: 'colour-technology-and-heritage',
    links: [
      {
        label: 'libraries',
        link: 'portfolio/office-library',
      },
      {
        label: 'bedrooms',
        link: 'portfolio/bedroom',
      },
    ],
    text: [
      [
        'Technology is constantly evolving and we are always looking for the latest cutting edge advancement to help improve our end '
    + 'product. Colour has always been one of the most important thoughts within your design and an integral part of our workflow. ',
      ],
      [
        'Colour changes the perceived size and feel of a room. It can make a small room feel larger or a large room cosier. It can be '
    + 'an instant mood changer, make you feel comforted or energised among many more emotions. So it is always an important choice for '
    + 'the end product.',
      ],
      [
        'At Wow Interior Design we have been able to match door colours to RAL colours for some time because we want to help you '
        + 'make the most of your design.',
      ],
      [
        'Now the latest innovations have allowed us to match your kitchen and bedroom furniture to Farrow and Ball colours. Farrow and Ball '
    + 'have long been a company at the cutting edge of interior design.',
      ],
      [
        'Whether you are looking to match a heritage colour or keep up with the latest trends?',
      ],
      [
        'If you are looking to get your kitchen and bedroom furniture to fit with your colour scheme?',
      ],
      [
        'Maybe you want to match a more striking feature within your design?',
      ],
      [
        'We at Wow can help you realise your dream interior.',
      ],
    ],
    image: 'swatch',
    alt: 'farrow and ball colour chart booklet',
  },
  {
    label: 'Working from home',
    link: 'working-from-home',
    links: [
      {
        label: 'office',
        link: 'portfolio/office-library',
      },
    ],
    text: [
      [
        'Whether you believe working from home can be more productive or the opposite, '
        + 'everyone has had to grow accustomed to their new working conditions within recent '
        + 'months. If you are a seasoned remote worker or you have only recently adopted the '
        + 'change, there are several factors which can affect your efficiency working from home. '
        + 'Not having to go to the office can offer surprising opportunities to develop a better '
        + 'work-life balance, and adapt your own working day, not to mention the money you will '
        + 'save from travel expenses. Recent studies have supported the idea that working from '
        + 'home—for the right people—can increase productivity and decrease stress. Research '
        + 'also suggests companies that encourage and support a work-from-home protocol '
        + 'actually save money in the long run—an added bonus on the employer side.',
      ],
      [
        'Your working environment, the space in which you will be spending the majority of your '
        + 'time. Quite simply, if you don’t like the space you are using, it can make the experience '
        + 'all the more tedious, so make sure it’s somewhere you enjoy spending time. A decent '
        + 'office chair for posture, the correct office furniture, some decorations around your desk, keeping the distractions to '
        + 'a minimum and some privacy can drastically improve your mood and overall efficiency. '
        + 'As video conferencing is becoming rapidly adopted, having a professional backdrop is a '
        + 'great way of keeping meetings as formal as possible, dressing as you would for work '
        + 'might also get you in the right mind set.',
      ],
      [
        'As you will be spending the majority of your time at home, keeping within a routine will '
        + 'help maintain your physical and mental health while taking regular short breaks can '
        + 'keep you energized and focused, and will also rest your eyes from continuous screen '
        + 'time. A breath of fresh air or a coffee break are good practices to get into the habit of. ',
      ],
      [
        'Here at Wow Interior Design we can help provide your perfect office environment with our fitted '
        + 'furniture solutions. Our designers can create the workspace & storage you need to meet your bespoke '
        + 'requirements. Contact us now for yor free, no-obligation design consultation.',
      ],
    ],
    image: 'office',
    alt: 'home office with fitted furniture and ample storage',
  },
];
